<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-card header-tag="header" footer-tag="footer" class="map-card">
        <div slot="header">
          <i class="icon-map mr-2"></i><strong> Vue Globale</strong>
          <info-message>
            Nous présentons ici l'ensemble de vos parcelles et de vos entrepôts.
          </info-message>
        </div>

        <div class="text-center" v-if="fetchingParcelsWarehouses">
          <b-spinner></b-spinner>
          <br>
          Chargement...
        </div>

        <div id="map" :data-map="this.map"
              :data-graphicsLayer="this.graphicsLayer" :data-view="this.view" v-else-if="parcels.length">
        </div>

        <p class="text-center" v-else>Aucune parcelle ou entrepôt dans cette exploitation.</p>
      </b-card>
    </div>
  </div>
</template>

<script>
  import {loadModules, loadCss} from "esri-loader";
  import {getArea} from 'ol/sphere.js';
  import {Modify} from 'ol/interaction';

  import {Api, Toast, env, asset} from "../../helpers/index";


  export default {
    name: "ExploitationGlobalView",
    title: "PIA - Vue Globale",
    data() {
      return {
        parcels: [1],
        warehouses: [],
        center: null,
        zoom: null,
        fetchingParcelsWarehouses: false,
        picture: null,
        name: '',
        cultureId: null,
        comment: '',
        submitted: false,
        area: '',
        map: null,
        vectorSource: null,
        drawInteraction: null,
        error: null,
        shapeType: 'Polygon',
        cultureOptions: [
          {value: "null", text: 'Aucune culture', selected: true, disabled: true}
        ],
        fetchingCreateParcel: false,
        marker: null,
        planarArea: null,
        graphicsLayer: null,
        MapView: null,
        view: null,
        sketch: null,
        Graphic: null,
        Sketch: null,
        Fullscreen: null,
        Polygon: null,
        Point: null
      }
    },
    created() {
      this.getWarehousesParcels()
      // await this.loadFirst();
    },
    async mounted() {
       // await this.renderMap()
      // await this.loadFirst();
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    methods: {
      asset,
      async loadMod(modules) {
        try {
          let parts;
          let modulesToSend = {};
          for (let module of modules) {
            parts = module.split("/");
            let [tmp] = await loadModules([module]);
            modulesToSend[parts[parts.length - 1]] = tmp;
          }

          return modulesToSend;
        } catch (error) {
          console.error(error);
        }
      },
      async loadMap() {
        loadCss()
        let modules = await this.loadMod([
          "esri/WebMap",
          "esri/views/MapView",
          "esri/layers/GraphicsLayer",
          "esri/widgets/Sketch",
          "esri/widgets/Fullscreen",
          "esri/geometry/geometryEngine",
          "esri/Graphic",
          "esri/geometry/projection",
          "esri/geometry/SpatialReference",
          "esri/geometry/Polygon",
          "esri/geometry/Point",
          "esri/symbols/PictureMarkerSymbol",
        ]);

        this.planarArea = modules.geometryEngine.planarArea
        this.graphicsLayer = new modules.GraphicsLayer();
        this.MapView = modules.MapView
        this.Graphic = modules.Graphic
        this.Sketch = modules.Sketch
        this.Fullscreen = modules.Fullscreen
        this.Polygon = modules.Polygon
        this.Point = modules.Point

        this.marker = new modules.PictureMarkerSymbol({
          url: env('WAREHOUSE_MARKER'),
          height: '35px',
          width: '20px'
        })

        this.map = new modules.WebMap({
          portalItem: {
            id: "d802f08316e84c6592ef681c50178f17" //imagery with label and transportation
            // id: "86265e5a4bbb4187a59719cf134e0018" // world imagery
          },
          layers: [this.graphicsLayer]
        });
      },
      addEventOnFeature() {
        this.sketch = new this.Sketch({
          view: this.view,
          availableCreateTools: ["polygon"],
          layer: this.graphicsLayer
        });

        const _this = this

        this.sketch.on("create", event => {
          switch (event.state) {
            case'start':
              _this.graphicsLayer.removeAll()
              console.log('Nous sommes dans le start')
              break
            case 'active':
              _this.formatPolygonArea(event.graphic.geometry)
              break
            case 'complete':
              _this.formatPolygonArea(event.graphic.geometry)
              console.log('Le complete: ', _this.area)
              break
          }
        });

        this.sketch.on("update", event => {
          _this.formatPolygonArea(event.graphics[0].geometry)
        });

        let polygonSymbol = this.sketch.viewModel.polygonSymbol;
        polygonSymbol.color = this.fillColor;
        polygonSymbol.outline = this.stroke;

        this.fullscreen = new this.Fullscreen({
          view: this.view
        });

        this.view.ui.add(this.sketch, "top-right");
        this.view.ui.add(this.fullscreen, "top-left");
      },
      async loadFirst() {
        await this.loadMap()

        this.view = new this.MapView({
          container: "map",
          map: this.map,
          center: this.center,//[12.354722, 7.369722],
          zoom: 6
        });

        this.addEventOnFeature()
      },
      async renderMap() {
        await this.loadMap()

        console.log('Le center: ', this.center)
        console.log('Le zomm: ', this.zoom)

        this.view = new this.MapView({
          container: "map",
          map: this.map,
          center: this.center,
          zoom: this.zoom
        });

        let fullscreen = new this.Fullscreen({
          view: this.view
        });

        this.view.ui.add(fullscreen, "top-left");

        let features = []

        if (this.parcels.length) {
          console.log("=========================================== Dans les parcelles =======")
          let polygon
          let feature

          for (let parcel of this.parcels) {
            polygon = new this.Polygon({
              spatialReference: {
                wkid: parcel.feature.wkid
              },
              rings: parcel.feature.coordinates
            })

            feature = new this.Graphic({
              geometry: polygon,
              symbol: {
                type: "simple-fill",
                color: parcel.fillColor,
                outline: parcel.stroke
              },
              attributes: {
                name: parcel.name,
                comment: parcel.comment,
                area: parcel.area + " ha"
              },
              popupTemplate: {
                title: "Informations sur la Parcelle",
                content: [{
                  type: "fields",
                  fieldInfos: [{
                    fieldName: "name",
                    label: "Nom"
                  }, {
                    fieldName: "area",
                    label: "Aire"
                  }, {
                    fieldName: "comment",
                    label: "Commentaire"
                  }]
                }]
              }
            })

            features.push(feature)
          }
        }

        if(this.warehouses.length){
          console.log("=========================================== Dans les warehouses =======")
          let point
          let featurePicture

          for(let warehouse of this.warehouses){
            point = new this.Point({
              spatialReference: {
                wkid: 102100
              },
              latitude: warehouse.latitude,
              longitude: warehouse.longitude
            })

            featurePicture = new this.Graphic({
              geometry: point,
              symbol: this.marker,
              attributes: {
                name: warehouse.name,
                comment: warehouse.comment,
                longitude: warehouse.longitude,
                latitude: warehouse.latitude
              },
              popupTemplate: {
                title: "Informations sur l'entrepôt",
                content: [{
                  type: "fields",
                  fieldInfos: [{
                    fieldName: "name",
                    label: "Nom"
                  }, {
                    fieldName: "longitude",
                    label: "Longitude"
                  }, {
                    fieldName: "latitude",
                    label: "Latitude"
                  },{
                    fieldName: "comment",
                    label: "Commentaire"
                  }]
                }]
              }
            })

            features.push(featurePicture)
          }
        }

        this.graphicsLayer.addMany(features)

      },
      getWarehousesParcels() {
        this.fetchingParcelsWarehouses = true
        Api.get('/exploitation/global-view', {
          exploitationId: this.exploitationId
        })
          .then(async res => {
            if (res.data.status === 'success' && res.data.data) {
              console.log(' ================================================= Lse data', res.data.data)
              this.parcels = res.data.data.parcels
              this.warehouses = res.data.data.warehouses
              this.center = res.data.data.center
              this.zoom = res.data.data.zoom
              this.fetchingParcelsWarehouses = false

              await this.renderMap();
            } else {
              this.error = res.data.error
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            console.log('======================== Erreur: ', error)

            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
      },
      drawStart_End() {
        let listener;
        let modify;
        let sketch;

        /*let formatPolygonArea = function (polygon) {
          let area = getArea(polygon);
          let output;
          if (area > 10000) {
            // (Math.round(area / 1000000 * 100) / 100)
            output = +((area / 1000000) * 100).toFixed(4) +
              ' ' + 'ha';
          } else {
            // (Math.round(area * 100) / 100)
            output = (area / 10000).toFixed(4) +
              ' ' + 'ha';
          }
          return output;
        };
        let circleArea = (radius) => {
          return ((Math.PI * radius * radius) / 10000).toFixed(4) +
            ' ' + 'ha';
        }*/
        
        const _this = this

        this.drawInteraction.on('drawstart',
          function (evt) {
            sketch = evt.feature;
            listener = sketch.getGeometry().on('change', _this.onChangeArea)
          });

        this.drawInteraction.on('drawend',
          function () {
            modify = new Modify({
              source: _this.vectorSource
            });
            _this.map.removeInteraction(_this.drawInteraction);
            _this.map.addInteraction(modify);
          });
      },
      addDrawInteraction() {
        if (!this.drawInteraction.getActive()) {
          this.drawInteraction.setActive(true);
          this.map.addInteraction(this.drawInteraction);
          this.drawStart_End();
        }
      },
      deletePolygon() {
        this.vectorSource.clear();
        this.area = '';
        this.drawInteraction.setActive(false)
        this.csvFile = null

        this.addDrawInteraction()
      },
      redirect() {
        this.$router.push({
          name: 'ExploitationParcels',
          params: {id: this.exploitationId}
        })
      },


    }
  }
</script>

<style scoped lang="stylus">
  @import "../../../node_modules/ol/ol.css"
  #map {
    width: 100%;
    height: 480px;
  }

  .draw-control {
    top: 50px;
    right: .5em;
  }

  .delete-control {
    top: 76px;
    right: .5em;
  }

  .map-card {
    /*min-height: 600px;*/
  }
</style>
